<template>
  <section>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <h2><span class="text-muted">Account Settings / </span><span>Profile</span></h2>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <b-nav pills>
          <b-nav-item active>
            <font-awesome-icon
              :icon="['far', 'user']"
              size="xl"
            />
            Account
          </b-nav-item>
          <b-nav-item :to="{ name: 'settings-billing' }">
            <font-awesome-icon
              :icon="['far', 'file-invoice']"
              size="xl"
            />
            Billing &amp; Plans
          </b-nav-item>
          <b-nav-item :to="{ name: 'settings-users' }">
            <font-awesome-icon
              :icon="['far', 'users']"
              size="xl"
            />
            Invite Users
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <!-- no resource loaded -->
    <b-card
      v-if="!resource.id"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        {{ resourceName }} Details
      </b-card-title>
      <div>
        <div class="p-1 mb-1 border-bottom rounded">
          <!-- <h5 class="text-capitalize">
            Key
          </h5> -->
          <p class="m-0">
            {{ resourceName }} data not found.
          </p>
        </div>
      </div>
    </b-card>

    <!-- profile details -->
    <b-card
      v-if="resource.id"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        {{ resourceName }} Details
      </b-card-title>

      <b-row>
        <b-col md="6">
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="resource.name"
              type="text"
              placeholder="Name"
              required
              @change="nameChanged"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Email Address"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="resource.email"
              type="email"
              placeholder="Enter email"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Domains"
          >
            <b-list-group id="domains-list">
              <b-list-group-item
                v-for="domain in resource.domains"
                :key="`domain_${domain.id}`"
              >
                {{ domain.domain }}
                <!-- <b-button
                  class="float-right"
                  size="sm"
                  variant="danger"
                  @click="deleteUserDomain(domain.id)"
                >
                  Delete
                </b-button> -->
              </b-list-group-item>
              <b-list-group-item
                v-if="resource.domains.length < 1"
              >
                <em>No domains added.</em>
              </b-list-group-item>
            </b-list-group>
            <b-button
              class="mt-1"
              size="sm"
              variant="outline-primary"
              @click="$bvModal.show('modal-domains')"
            >
              Change Domains
            </b-button>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Default Currency"
            label-for="currency-select"
          >
            <v-currency-select
              id="currency-select"
              v-model="resource.currency"
              @input="currencyChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- agency details -->
    <b-card
      v-if="resource.id"
      class="mt-2"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        Agency Details
      </b-card-title>

      <b-row>
        <b-col md="6">
          <!-- set google account -->
          <link-google-account />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <select-google-customer
            :model-value="currentCustomer"
            @setCurrentAgency="newValue => currentAgency = newValue"
            @setCurrentCustomer="newValue => currentCustomer = newValue"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- conversion details -->
    <b-card
      v-if="resource.id"
      class="mt-2"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        Conversion Rate
      </b-card-title>

      <b-row>
        <b-col>
          <!-- set conversion value -->
          <set-conversion-value
            v-if="userData"
            :current-user="userData"
            :current-domains="userData.domains"
            @updateConversionValue="updateConversionValue"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal add domain -->
    <b-modal
      id="modal-domains"
      centered
      title="Modify Domains"
      size="lg"
      ok-only
      ok-title="Cancel"
      ok-variant="outline-secondary"
    >
      <!-- set site urls -->
      <manage-site-domains
        :current-agency="currentAgency"
        @updateDomains="updateDomains"
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle,
  BButton,
  BFormGroup, BFormInput,
  BListGroup, BListGroupItem,
  BNav, BNavItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vCurrencySelect from 'vue-currency-select'
import LinkGoogleAccount from '@/views/components/link-google-account/LinkGoogleAccount.vue'
import SelectGoogleCustomer from '@/views/components/select-google-customer/SelectGoogleCustomer.vue'
import ManageSiteDomains from '@/views/components/manage-site-domains/ManageSiteDomains.vue'
import SetConversionValue from '@/views/components/set-conversion-value/SetConversionValue.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BNav,
    BNavItem,

    // currency selector
    vCurrencySelect,

    // google customer settings
    LinkGoogleAccount,
    SelectGoogleCustomer,
    ManageSiteDomains,
    SetConversionValue,
  },

  data() {
    return {
      resourceName: 'Profile',
      resourceNamePlural: 'Profiles',
      resource: {},
      // userCurrencies: {},
      currentAgency: null, // current agency client
      currentCustomer: null, // current google customer
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
  },
  created() {
    this.refreshUserData()
  },
  methods: {
    /**
     * refreshes userdata when userdata is changed
     */
    refreshUserData() {
      // refresh userdata
      this.$store.dispatch('users/fetchCurrentUser')
        .then(response => {
          console.log('refreshUserData', response)
          this.resource = response
          this.selectedCustomer = response.google_id // set selected customer
        })
    },

    /* HANDLERS */

    /* getUserCurrencies(userId) {
      // get all of the possible currencies for the customer that owns this conversion
      useJwt.getCurrencies(userId)
        .then(response => {
          const responseData = response.data.data
          console.log('getCurrencies', responseData)
          this.userCurrencies = responseData
        })
        .catch(error => {
          console.log(error.response.data)
        })
    }, */
    nameChanged(newValue) {
      // set new user name
      // TODO: why does updating the input update userData.name? using userData.fullName
      if (newValue !== this.userData.fullName) {
        useJwt.updateCurrentUser({ name: newValue })
          .then(response => {
            const responseData = response.data.data
            // this.updateUserData(responseData)
            console.log('updateCurrentUser', responseData)
            this.refreshUserData()

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Name Updated',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your name has been updated.',
              },
            })
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },
    currencyChanged(newValue) {
      // set new default currency
      console.log('currencyChanged', newValue)
      if (newValue !== this.userData.currency) {
        useJwt.updateDefaultCurrency(newValue)
          .then(response => {
            const responseData = response.data.data
            // this.updateUserData(responseData)
            console.log('updateDefaultCurrency', responseData)
            this.refreshUserData()

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Currency Updated',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your default currency has been updated.',
              },
            })
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },
    updateDomains(newDomainData) {
      // update domains for this page
      this.domains = newDomainData
    },
    updateConversionValue() {
      // update conversion values when form changed
      this.refreshUserData()
    },

    /* MODAL */

    showErrorMessage(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: errorMessage,
        },
      },
      {
        timeout: false,
      })
    },

    /* HELPERS */

    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
  },
}
</script>
